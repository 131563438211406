<template>
  <div class="home">
    <img class="background" src="../assets/background.png" />
    <div class="box">
      <div class="container">
        <div class="introduce">
          <div class="logo_title">
            <img class="logo" src="../assets/logo.png" />
            <p class="title">OMO复古相机</p>
          </div>
          <div class="description">拍人好看的复古相机</div>
          <div class="content_1">
            OMO复古相机是一款结合复古风格与现代技术的摄影应用，以模拟复古CCD数码相机为特色，提供多款相机和胶卷模拟效果，同时拥有美颜、瘦脸、大眼等流行功能，让每一张照片都充满胶片颗粒的质感。
          </div>
          <div class="content_2">
            就像你的口袋摄影师，无需复杂的编辑，只需轻轻一按，即刻享受80年代复古胶片相机的拍摄体验，为你的照片增添更多乐趣和个性。
          </div>
          <div class="appStore">
            <a href="https://apps.apple.com/app/id6502740482" target="_blank">
              <img class="apple_store" src="../assets/apple_link.png" />
            </a>
            <a href="https://www.pgyer.com/Gu9rCqHY" target="_blank">
              <img class="android_store" src="../assets/android_link.png" />
            </a>
          </div>
          <div class="qrCode">
            <p class="qrCode_label">联系我们</p>
            <img class="qrCode_image" src="../assets/qr_code.png" />
          </div>
        </div>
        <div class="preview_container">
          <img class="productName" src="../assets/production_logo.png" />
          <div class="preview">
            <div class="preview1">
              <img class="preview1_pre" src="../assets/preview_1.png" />
              <img class="preview1_camera" src="../assets/camera_1.png" />
            </div>
            <div class="preview2">
              <img class="preview2_camera" src="../assets/camera_2.png" />
              <img class="preview2_pre" src="../assets/preview_2.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <img src="../assets/police.png" />
        <p class="records">
          津ICP备2024019065号-1&nbsp;&nbsp;&nbsp;&nbsp;津ICP备2024019065号-2A
          &nbsp;&nbsp;版权所有©天津欧喵科技有限公司&nbsp;&nbsp;|&nbsp;&nbsp;
        </p>
        <a href="https://omocam.com/privacy" target="_blank" class="privacy"
          >隐私政策</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.home {
  position: relative;
  width: 100%;
  height: 100%;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;

  .introduce {
    display: flex;
    flex-direction: column;
    align-items: start;

    .logo_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 70px;

      .logo {
        width: 80px;
        height: 80px;
      }

      .title {
        margin-left: 20px;
        background: linear-gradient(to bottom, #ffe8c5, 50%, white);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 40px;
        font-weight: 900;
      }
    }

    .description {
      margin-top: 100px;
      color: white;
      font-size: 25px;
    }
    .content_1 {
      margin-top: 40px;
      color: #a9a9a9;
      font-size: 15px;
      width: 500px;
    }
    .content_2 {
      margin-top: 20px;
      color: #a9a9a9;
      font-size: 15px;
      width: 500px;
    }
    .appStore {
      margin-top: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .apple_store {
        width: 180px;
        height: 62px;
      }
      .android_store {
        margin-left: 50px;
        width: 180px;
        height: 62px;
      }
    }
    .qrCode {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;
      .qrCode_image {
        margin-top: 20px;
        width: 120px;
        height: 120px;
        border-radius: 15px;
        display: block;
      }

      .qrCode_label {
        color: #a9a9a9;
        font-size: 17px;
      }
    }
  }

  .preview_container {
    display: flex;
    flex-direction: column;
    align-items: end;

    .productName {
      margin-top: 50px;
      width: 200px;
      height: 70px;
    }

    .preview {
      display: flex;

      .preview1 {
        margin-top: 120px;
        display: flex;
        flex-direction: column;

        .preview1_camera {
          width: 320px;
          height: 197px;
        }

        .preview1_pre {
          width: 300px;
          height: 557px;
        }
      }

      .preview2 {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        .preview2_camera {
          width: 320px;
          height: 165px;
        }

        .preview2_pre {
          width: 300px;
          height: 557px;
        }
      }
    }
  }
}

.copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 30px;
  .records {
    margin-left: 10px;
    color: white;
    font-size: 12px;
  }

  .privacy {
    color: white;
    font-size: 12px;
  }
}
</style>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class HomeView extends Vue {}
</script>
