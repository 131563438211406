<template>
  <router-view />
</template>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans SC";
  box-sizing: border-box;
  outline: none !important;
}

#app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/background.png);
}
</style>
